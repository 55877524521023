import { useState, useEffect } from 'react';
import '../../scss/pages/Pricing.scss';
import placeholderImg from '../../images/undraw_goal_-0-v5v.svg';
import {useTranslation} from "react-i18next";
function Pricing() {
	const {t, i18n} = useTranslation('common');
	const [mainPartVisible, setMainPartVisible] = useState(true);
	const [billing, setBilling] = useState('monthly');
	const [selectedPackage, setSelectedPackage] = useState(1);
	const [finalPricing, setFinalPricing] = useState();
	const availablePackages = [
		{
			id: 1,
			type: 'normal',
			replies: 250,
			monthlyPrice: 0,
			yearlyPrice: 0,
			yearlyDiscount: 20 //Percentage
		},
		{
			id: 2,
			type: 'normal',
			replies: 2500,
			monthlyPrice: 40,
			yearlyDiscount: 20
		},
		{
			id: 3,
			type: 'normal',
			replies: 6000,
			monthlyPrice: 80,
			yearlyDiscount: 20
		},
		{
			id: 4,
			type: 'normal',
			replies: 10000,
			monthlyPrice: 120,
			yearlyDiscount: 20
		},
		{
			id: 5,
			type: 'normal',
			replies: 20000,
			monthlyPrice: 160,
			yearlyDiscount: 20
		},
		{
			id: 6,
			type: 'normal',
			replies: 35000,
			monthlyPrice: 200,
			yearlyDiscount: 20
		},
		{
			id: 7,
			type: 'normal',
			replies: 50000,
			monthlyPrice: 240,
			yearlyDiscount: 20
		},
		{
			id: 8,
			type: 'custom',
			replies: null,
			monthlyPrice: null,
			yearlyDiscount: null
		}
	];
	const getPackageCss = (package_id) => {
		let o = 'pricing__item '
		if(package_id == selectedPackage) o += ' pricing__itemActive'
		return o;
	}
	const compYearlyPricing = (monthlyPricing, discount) => {
		const fullAmount = monthlyPricing * 12;
		let disc = Math.round((fullAmount * discount) / 100).toFixed(0);
		return [fullAmount - disc, fullAmount];
	}
	function compFinalPricing(){
		const s = availablePackages.filter( (pkg) => pkg.id == selectedPackage);
		if(s.length > 0){
			if (billing == 'monthly'){
				setFinalPricing(s[0].monthlyPrice ? `${s[0].monthlyPrice}€` : t('pricing.free'))
			}else{
				setFinalPricing(s[0].monthlyPrice ? `${compYearlyPricing(s[0].monthlyPrice, s[0].yearlyDiscount)[0]}€` : t('pricing.free'))
			}
		}
	}
	const registerNow = () => {
		const o = {selectedPackage: selectedPackage, billing: billing}
		localStorage.setItem('selectedPricingPackage', JSON.stringify(o))
		window.location = 'http://localhost:3000'
		//console.log(JSON.parse(localStorage.getItem('selectedPricingPackage')))
	}
	useEffect(() => {
		compFinalPricing()
	}, [selectedPackage, billing])
	return (
		<div className="pricing">
			<div className="section__title">{t('pricing.header')}</div>
			{ mainPartVisible &&
				<div className="pricing__wrapper">
					<div className="text--center" style={{width: '100%'}}>
						<img src={placeholderImg} width="220" />
						<h1>
							<b className="text--warning">{t('pricing.comingSoon')}</b>
							{/* <div className="font--25">{t('pricing.comingDemo')}</div> */}
						</h1>
					</div>
				</div>
			}
			{ !mainPartVisible && 
				<div className="pricing__wrapper">
					<div className="pricing__wrapper-left">
						{ availablePackages.map( (pkg, idx) => {
							return (
								<div className={getPackageCss(pkg.id)} key={`package_${idx}`} onClick={() => setSelectedPackage(pkg.id)} >
									<div className="pricing__item-inner">
										<span className="material-icons unchecked">
											radio_button_unchecked
										</span>
										<span className="material-icons checked">
											radio_button_checked
										</span>
										{ pkg.type == 'custom' ?
											<div className="float--right text--bold font--35 mleft--25">
												{t('pricing.moreReplies')}
												<div className="text--muted font--15">
													{t('pricing.moreRepliesSub')}
												</div>
											</div>
											:
											<>
												<span className="vm--align mtop--5 mleft--30 font--20">
													{pkg.replies.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} <i>{t('pricing.replies')}</i>
													<div className="text--muted mtop--5">
														{t('pricing.perMonth')}
													</div>
												</span>
												<div className="float--right text--bold font--35">
													{ pkg.monthlyPrice == 0 ?
														t('pricing.free')
														:
														<>
															{billing == 'monthly' && <>{pkg.monthlyPrice}€ <span className="text--muted"> / {t('pricing.month')}</span></>}
															{billing == 'yearly' &&
																<div className="text--right font--35">
																	{compYearlyPricing(pkg.monthlyPrice, pkg.yearlyDiscount)[0]}€ <span className="text--muted"> / {t('pricing.year')}</span>
																	<br />
																	<div>
																		<span style={{textDecoration: 'line-through'}}>{compYearlyPricing(pkg.monthlyPrice, pkg.yearlyDiscount)[1]}€</span>
																		<div className="btn btn-danger btn-sm mtop--10 font--13">-{pkg.yearlyDiscount}%</div>
																	</div>
																</div>
															}
														</>
													}
												</div>
											</>
										}
									</div>
								</div>
							)
						})}
					</div>
					<div className="pricing__wrapper-right">
						<div className="mtop--20 text--bold font--40">
							{t('pricing.billed')}
						</div>
						<div className="flex flex--row justify--center mtop--20">
							<div className={ billing == 'monthly' ? 'btn btn-primary btn-lg' : 'btn btn-primary-soft btn-lg'}  onClick={() => setBilling('monthly')}>{t('pricing.monthly')}</div>
							<div className={ billing == 'yearly' ? 'btn btn-primary btn-lg' : 'btn btn-primary-soft btn-lg'} onClick={() => setBilling('yearly')}>{t('pricing.yearly')}</div>
						</div>
						<br />
						<br />
						<br />
						<div className="text--bold font--35 mtop--20">
							{t('pricing.allPlansInclude')}
						</div>
						<ul className="mtop--10 font--25 pricing__list">
							<li>
								{t('pricing.unlimited')}
							</li>
							<li>
								{t('pricing.custombranding')}
							</li>
							<li>
								{t('pricing.allTypes')}
							</li>
						</ul>
						<div className="btn btn-primary font--35 mtop--20 btn-50" onClick={ () => registerNow() }>
							{t('pricing.startNow')}	 <b className="pricing__start-price">{finalPricing}</b>
						</div>
					</div>
				</div>
			}
		</div>
	);
}

export default Pricing;
