import { useState } from 'react';
import '../../scss/pages/Footer.scss';
import {useTranslation} from "react-i18next";

function Footer() {
	const {t, i18n} = useTranslation('common');
	const currentYear = new Date().getFullYear();
	return (
		<div className="footer flex flex--column justify--center">
			<div className="footer__item flex flex--row justify--center">
				<span className="text--normal">©Copyright {currentYear}</span> <div className="logo logo__footer"></div>
			</div>
			<div className="flex flex--row flex--wrap justify--center">
				<a className="footer__item" href="/terms">
					{t('footer.terms')}
				</a>
				<a className="footer__item" href="/privacy">
					{t('footer.privacy')}
				</a>
				<a className="footer__item" href="/faq">
					{t('footer.faq')}
				</a>
			</div>
			
		</div>
	);
}

export default Footer;
