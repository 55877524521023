import '../../scss/pages/HowTo.scss';
//import appPreview from '../../images/landing/appreview.png';
import {useTranslation} from "react-i18next";
/* import arrowLeft from '../../images/landing/arrowleft.png'
import realvsbarxa from '../../images/landing/realvsbarxa.jpg'
import lakers from '../../images/landing/simon-ray-wvetcyGHgMk-unsplash.jpg' */
import Lottie from 'react-lottie-player';
import embedJson from '../../animations/embed.json';
import shareJson from '../../animations/share.json';

function HowTo() {
	const {t, i18n} = useTranslation('common');
	return (
		<div className="how_to">
			<div className="section__title">
				{t('howto.header')}
			</div>
			<div className="how_to__wrapper mtop--20">
				<div className="mtop--20 text--center">
					<Lottie
						loop
						animationData={embedJson}
						play
						style={{ width: 350, height: 350, margin: 'auto' }}
					/>
					<h1>{t('howto.embed')}</h1>
					<div>{t('howto.embedDetails')}</div>
				</div>
				<h1 className="text__bold text--primary mtop--20">
					OR
				</h1>
				<div className="mtop--10 text--center">
					<Lottie
						loop
						animationData={shareJson}
						play
						style={{ width: 350, height: 350, margin: 'auto' }}
					/>
					<h1>{t('howto.share')}</h1>
					<div>{t('howto.shareDetails')}</div>
				</div>
				{/* <div className="how_to__left text--center">
					<div className="how__to-website">
						<div className="how__to-websiteHeader font--30">
							<b className="text--primary">Your</b>Website.com
							<span className="material-icons font__0 float--right">menu</span>
						</div>
						<h3 className="mleft--20">{t('howto.articles')}</h3>
						<div className="mtop--20 card card__rounded card__glass">
							<div className="card__header">{t('howto.article1Header')}</div>
							<div className='card__body flex flex--row article__card'>
								<div className="flex--50">
									<img src={realvsbarxa} className="img__responsive" />
								</div>
								<div className="mleft--10 flex--50">
									{t('howto.article1Content')}
								</div>
							</div>
						</div>
						<div className="how__to-card mtop--20">
							<div className="how__to-card-placeholder">
								<span className="material-icons font--40 text--primary">add</span>
								{t('howto.embed')}
								<div className="card card__danger">
									{t('howto.embedDetails')}
								</div>
							</div>
							<img src={arrowLeft} className="arrow__left" />
							<div className="mtop--20 card card__rounded card__glass how__to-card-primary">
								<img src={appPreview} className="img__responsive" />
							</div>
						</div>
						<div className="mtop--20 card card__rounded card__glass">
							<div className="card__header">{t('howto.article2Header')}</div>
							<div className='card__body flex flex--row article__card'>
								<div className="flex--50">
									<img src={lakers} className="img__responsive" />
								</div>
								<div className="mleft--10 flex--50">
									{t('howto.article2Content')}
								</div>
							</div>
						</div>
					</div>
				</div> */}
			</div>
		</div>
	);
}

export default HowTo;
