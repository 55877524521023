import { useEffect, useState } from 'react';
import ThemeSwitch from '../utils/ThemeSwitch';
import { useTranslation } from "react-i18next";
import enImg from '../../images/united-kingdom-flag-icon.svg';
import elImg from '../../images/greece-flag-icon.svg';

function Header() {
	const { t, i18n } = useTranslation('common');
	const [currentLanguage, setCurrentLanguage] = useState('en')
	const [mobileMenu, setMobileMenu] = useState(false)
	const goTo = (link, closeMobileMenu) => {
		if (window.location.pathname != '/'){
			window.location = '/'
			return false;
		}
		if (closeMobileMenu) setMobileMenu(false);
		document.getElementById(link).scrollIntoView({
			behavior: 'smooth'
		});
	}
	const changeLanguage = lang => {
		i18n.changeLanguage(lang)
		setCurrentLanguage(lang)
	}
	useEffect(() => {
		setCurrentLanguage(i18n.language)
	}, [])
	return (
		<>
			<div className="page__main-menu">
				<a className="page__main-logo text--normal" onClick={() => goTo('main')}>
					<div className="logo"></div>
				</a>
				<div className="page__main-mobileMenu">
					<div className="btn btn-secondary" onClick={() => setMobileMenu(true)}>
						<span className="material-icons vm--align font--35">menu</span>
					</div>
				</div>
				<div className="page__main-items">
					<div onClick={() => goTo('howto')} className="page__main-item">
						{t('menu.howTo')}
					</div>
					<div onClick={() => goTo('pricing')} className="page__main-item">
						{t('menu.pricing')}
					</div>
					<div onClick={() => goTo('contact')} className="page__main-item">
						{t('menu.contact')}
					</div>
					{/* <a className="btn btn-primary text--normal" href="/login">
						<span className="material-icons font--20 vm--align">
							login
						</span>
						&nbsp;
						<span className="vm--align">
							{t('menu.login')}
						</span>
					</a> */}
					<ThemeSwitch />
					{currentLanguage == 'el' &&
						<div className="btn btn-secondary btn-md" onClick={() => changeLanguage('en')}>
							<img src={enImg} width="20" />
						</div>
					}
					{currentLanguage == 'en' &&
						<div className="btn btn-secondary btn-md" onClick={() => changeLanguage('el')}>
							<img src={elImg} width="20" />
						</div>
					}
				</div>
			</div>
			{
				mobileMenu &&
				<div className="mobile__menu">
					<div className='flex flex--row'>
						<h1 className="mtop--20">Menu</h1>
						<span className="btn btn-sm material-icons font--40 float--right" onClick={() => setMobileMenu(false)}>
							close
						</span>
					</div>
					<div className="mtop--20">
						<div onClick={() => goTo('howto', true)} className="item">How to use?</div>
						<div onClick={() => goTo('pricing', true)} className="item">Pricing</div>
						<div onClick={() => goTo('contact', true)} className="item">Contact</div>
						<a className="btn btn-primary text--normal btn-100" href="/login">
							<span className="material-icons font--35 vm--align">
								login
							</span>
							&nbsp;
							<span className="vm--align">Login</span>
						</a>
						<br />
						<ThemeSwitch />
					</div>
				</div>
			}
		</>
	);
}

export default Header;
