import { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
const Terms = () => {
	const [html, setHtml] = useState('');
	useEffect(() => {	
		fetch('https://sportovivo.com/terms.txt')
		.then(r => {
			if(r.ok){
				return r.text()
			}else{
				console.log('Could not load terms');
			}
			
		})
		.then((data) => {
			setHtml(data)
		})
	}, [])
	return (
		<div className="page__main">
			<Header />
			<div className="page__centered">
				<div className="section__header">TERMS OF SERVICE</div>
				<div dangerouslySetInnerHTML={{ __html: html }}></div>
			</div>
			<Footer />
		</div>
	)
}
	
export default Terms