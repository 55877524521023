import { useRef } from 'react';
import '../../scss/pages/PromoVideo.scss';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from "react-i18next";

const Mobile = ({ children }) => {
	const isMobile = useMediaQuery({ maxWidth: 767 })
	return isMobile ? children : null
}
const Default = ({ children }) => {
	const isNotMobile = useMediaQuery({ minWidth: 768 })
	return isNotMobile ? children : null
}

function PromoVideo() {
	const { t, i18n } = useTranslation('common');
	const videoRef = useRef()
	return (
		<div className="promo_video">
			<Default>
				<video autoPlay loop controls muted playsInline defaultmuted="true" preload="auto"
					ref={(elem) => { if (elem) elem.volume = 0.2 }}
					onLoadStart={() => {
						console.log('onLoadStart', new Date());
					}}
				>
						<source src="https://conferiencedev.s3.eu-west-1.amazonaws.com/videos/sportovivo/Sportovivo-Features-Extended-Desktop-web.mp4" type="video/mp4" />
				</video>
			</Default>
			<Mobile>
				<video autoPlay loop controls muted playsInline defaultmuted="true" preload="auto"
					ref={(elem) => { if (elem) elem.volume = 0.2 }}
					onLoadStart={() => {
						console.log('onLoadStart', new Date());
					}}
				>
						<source src="https://conferiencedev.s3.eu-west-1.amazonaws.com/videos/sportovivo/sportovivo-features-EXTENDED-vert-web.mp4" type="video/mp4" />
				</video>
			</Mobile>
		</div>
	);
}

export default PromoVideo;
