import { useState } from 'react';
import Main from "./components/Main/Main";
import ComingSoon from "./components/ComingSoon";
import Login from "./components/Login";
import Terms from "./components/Main/Terms";
import Privacy from "./components/Main/Privacy";
import Faq from "./components/Main/Faq";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
	return (
		<div className="App page">
			<BrowserRouter>
				<Routes>
					<Route index path="/" element={<Main />} />
					<Route path="/login"  element={<Login />} />
					<Route path="/register"  element={<Login />} />
					<Route path="/terms" element={<Terms />} />
					<Route path="/privacy" element={<Privacy />} />
					<Route path="/faq" element={<Faq />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
