import { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import { useTranslation } from "react-i18next";
import '../../scss/pages/Faq.scss';

const Faq = () => {
	//const [html, setHtml] = useState('');
	const { t, i18n } = useTranslation('common');
	const faqs = {
		'en': [
			{
				'title': 'What is SportoviVo?',
				'text': 'Sportovivo is an online platform that helps you build digital sports campaigns and interact with your audience quickly and effortlessly.'
			},
			{
				'title': 'Why use SportoVivo?',
				'text': 'With features like Score Prediction, Lineup selector, Polls, Leads form, Wheel of fortune and other gamification features, you can engage your audience, and at the same time collect user data & increase your database of subscribers.'
			},
			{
				'title': 'Who can use SportoVivo',
				'text': 'Anyone! From sports teams to publishers, sports websites, influencers, betting companies you name it! Everyone that has an audience to interact with is welcome to use our platform.'
			},
			{
				'title': 'Does SportoVivo offer a free plan?',
				'text': 'Yes! Free plan is available for new and existing customers that want to try our services with smaller number of responses.'
			},
			{
				'title': 'How much does Sportovivo cost?',
				'text': 'We offer flexible pricing plans based on answers volume. You can start free without any obligation. Pricing plans will soon be uploaded in our website. Until then, you can contact us at info@sportovivo.com and get an instant quote by our experts.'
			},
			{
				'title': 'Can I buy a license for White label?',
				'text': 'Yes, you can use the platform of Sportovivo with your brand on it. Just contact us to provide more information.'
			},
			{
				'title': 'We are Marketing Agencies and we want to use it on behalf of our clients.',
				'text': 'Excellent. You can be the main admin and create organizations under your umbrella. You can then create campaigns for your clients'
			}
		],
		'el': [
			{
				'title': 'Τι είναι το SportoviVo;',
				'text': 'Το Sportovivo είναι μια διαδικτυακή πλατφόρμα που σας βοηθά να δημιουργήσετε ψηφιακές αθλητικές καμπάνιες και να αλληλεπιδράσετε με το κοινό σας γρήγορα και χωρίς κόπο.'
			},
			{
				'title': 'Γιατί να χρησιμοποιήσετε το SportoVivo;',
				'text': 'Με λειτουργίες όπως Πρόβλεψη βαθμολογίας, Επιλογέας σειράς, Δημοσκοπήσεις, Φόρμα Δυνατοτήτων, Τροχός της τύχης και άλλες λειτουργίες παιχνιδιού, μπορείτε να προσελκύσετε το κοινό σας και ταυτόχρονα να συλλέξετε δεδομένα χρήστη και να αυξήσετε τη βάση δεδομένων των συνδρομητών σας.'
			},
			{
				'title': 'Ποιος μπορεί να χρησιμοποιήσει το SportoVivo',
				'text': 'Ο καθενας! Από αθλητικές ομάδες μέχρι εκδότες, αθλητικές ιστοσελίδες, επιρροές, στοιχηματικές εταιρείες όπως το λες! Όλοι όσοι έχουν κοινό να αλληλεπιδράσουν είναι ευπρόσδεκτοι να χρησιμοποιήσουν την πλατφόρμα μας.'
			},
			{
				'title': 'Προσφέρει το SportoVivo δωρεάν πρόγραμμα;',
				'text': 'Ναί! Διατίθεται δωρεάν πρόγραμμα για νέους και υπάρχοντες πελάτες που θέλουν να δοκιμάσουν τις υπηρεσίες μας με μικρότερο αριθμό απαντήσεων.'
			},
			{
				'title': 'Πόσο κοστίζει το Sportovivo;',
				'text': 'Προσφέρουμε ευέλικτα προγράμματα τιμολόγησης με βάση τον όγκο των απαντήσεων. Μπορείτε να ξεκινήσετε δωρεάν χωρίς καμία υποχρέωση. Τα προγράμματα τιμολόγησης θα ανέβουν σύντομα στον ιστότοπό μας. Μέχρι τότε, μπορείτε να επικοινωνήσετε μαζί μας στο info@sportovivo.com και να λάβετε μια άμεση προσφορά από τους ειδικούς μας.'
			},
			{
				'title': 'Μπορώ να αγοράσω άδεια χρήσης για τη White label;',
				'text': 'Ναι, μπορείτε να χρησιμοποιήσετε την πλατφόρμα του Sportovivo με την επωνυμία σας. Απλώς επικοινωνήστε μαζί μας για να παρέχουμε περισσότερες πληροφορίες.'
			},
			{
				'title': 'Είμαστε Marketing Agencies και θέλουμε να το χρησιμοποιούμε για λογαριασμό των πελατών μας.',
				'text': 'Εξοχος. Μπορείτε να είστε ο κύριος διαχειριστής και να δημιουργήσετε οργανισμούς υπό την ομπρέλα σας. Στη συνέχεια, μπορείτε να δημιουργήσετε καμπάνιες για τους πελάτες σας'
			}
		],
	};
	return (
		<div className="page__main">
			<Header />
			<div className="section__header">FAQ</div>
			<div className="mtop--20 faqs">
				{ faqs[i18n.language].map((faq, idx) => {
					return(
						<div class="tab" key={`faq__${idx}`}>
							<input type="checkbox" id={`faq__${idx}`} />
							<label class="tab-label" for={`faq__${idx}`}>{faq.title}</label>
							<div class="tab-content">
								{faq.text}
							</div>
						</div>
					)
				})
				}
			</div>
			<Footer />
		</div>
	)
}

export default Faq