import { useState } from 'react';
import '../../scss/pages/Contact.scss';
import {useTranslation} from "react-i18next";

function Contact() {
	const {t, i18n} = useTranslation('common');
	return (
		<div className="contact mtop--20">
			<div className="text--center contact__wrapper">
				<h1>{t('contact.title')}</h1>
				<div className="mtop--20 font--30 text--primary text--bold">info@sportovivo.com</div>
				<svg viewBox="0 0 202.819 45.163" className="hero__underline"><path d="M2.12 9.857L156.9 5.515l43.806-1.229V0a749.549 749.549 0 00-114.1 22.4 2.146 2.146 0 00.584 4.209 109.73 109.73 0 0133.39 5.281v-4.129l-30 9.532a2.146 2.146 0 000 4.132 96.378 96.378 0 0022.862 3.736c2.827.118 2.821-4.167 0-4.285a91.282 91.282 0 01-21.694-3.583v4.132l30-9.532a2.147 2.147 0 000-4.132 113.829 113.829 0 00-34.558-5.434l.584 4.209a742.128 742.128 0 01112.935-22.25c2.726-.318 2.9-4.367 0-4.285L45.927 4.343 2.12 5.572c-2.821.079-2.833 4.365 0 4.285z"></path></svg>
			</div>
		</div>
	);
}

export default Contact;
