import { useState } from 'react';
import '../scss/pages/Login.scss';
import Footer from './Main/Footer';

function Login() {
	return (
		<>
			<div className="login">
				<div className="login__form">
					<div className="login__form-header">
						<a className="page__main-logo text--normal" href="/">
							<span className="material-icons text--primary font--35">
								bar_chart
							</span>
							<b className="font--25 vm--align">More<span className="text--primary">Polls</span></b>
							<br />
							<i className="font--15">
								Spice up your campaigns
							</i>
						</a>
					</div>
					<div className="mtop--20">
						<label className="login__title">Login with email</label>
						<div className="mtop--20">
							<label className="text--bold text--muted ">Email</label>
							<input type="text" placeholder="Type here..."/>
						</div>
						<div className="mtop--20">
							<label className="text--bold text--muted ">Password</label>
							<input type="password" placeholder="Type here..."/>
						</div>
						<div className="mtop--20">
							<div className="btn btn-primary btn-100">Login</div>
						</div>
						<div className="mtop--20 dashed__hr">
							<label className="login__title">Third party login</label>
							<button className="btn btn-primary-pastel btn-100">
								<i className="fa-brands fa-google font--20 vm--align text--danger"></i>
								&nbsp;
								Google
							</button>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default Login;
