import {  useState } from 'react';
import Typed from 'react-typed';
import HowTo from './HowTo';
import Pricing from './Pricing';
import Contact from './Contact';
import Footer from './Footer';
import Header from './Header';
import {useTranslation} from "react-i18next";
import '../../scss/pages/Landing.scss'
import scorePredictionImg from '../../images/landing/scoreprediction.png'
import registrationImg from '../../images/landing/registration.png'
import manOfTheMatchImg from '../../images/landing/manofthematch.png'
import lineUpSelectorImg from '../../images/landing/lineupselector.png'
import wheelImg from '../../images/landing/wheel.png'
import scratchImg from '../../images/landing/scratch.png'
import RenderIfVisible from 'react-render-if-visible'
import PromoVideo from './PromoVideo';

function Main() {
	const {t, i18n} = useTranslation('common');
	const [example, setExample] = useState('https://app.sportovivo.com/PT4qlaZ3qGkGSloXKc0P')
	const [isFullHeight, setIsFullHeight] = useState(false);

	const features = [
		{
			'type': 'numericPrediction',
			'title': 'Score prediction',
			'text': 'testing',
			'image': scorePredictionImg,
			'iframe': 'https://app.sportovivo.com/share?campaign=2X0380l0s4oRqQdLEotz&org=IZH7v6xC5jttjboT1T5r'
		},
		{
			'type': 'poll',
			'title': 'Polls',
			'text': 'testing',
			'image': registrationImg,
			'iframe': 'https://app.sportovivo.com/share?campaign=B8SzwhYvfqp8duAXK9TS&org=IZH7v6xC5jttjboT1T5r'
		},
		{
			'type': 'mvp',
			'title': 'MVP',
			'text': 'testing',
			'image': manOfTheMatchImg,
			'iframe': 'https://app.sportovivo.com/share?campaign=I5F5IE10EVJ9gL0DtIuZ&org=IZH7v6xC5jttjboT1T5r'
		},
		{
			'type': 'wheel',
			'title': 'Wheel of fortune',
			'text': 'testing',
			'image': wheelImg,
			'iframe': 'https://app.sportovivo.com/share?campaign=NIGhgGVm4pohixXgKrXn&org=IZH7v6xC5jttjboT1T5r'
		},
		{
			'type': 'Lineup',
			'title': 'Lineup selector',
			'text': 'testing',
			'image': lineUpSelectorImg,
			'iframe': 'https://app.sportovivo.com/Gl4SV4H9VdeGqqV4Ggjn/ZN7ZTh6sUvTtWzRQTY3I'
		},
		{
			'type': 'scratch',
			'title': 'Scratch',
			'text': 'testing',
			'image': scratchImg,
			'iframe': 'https://app.sportovivo.com/HJPZeMfPCuabAjr1NNev/IZH7v6xC5jttjboT1T5r'
		}
	];
	const [currentFeature, setCurrentFeature] = useState(features[0])
	return (
		<div className="page__main landing">
			<div className="page__main-content">
				<Header />
				<div className="flex flex--row flex--wrap" id="main">
					<div className='left'>
						<div className="page__main-container">
							<div className="page__main-title">
								{t('main.header')}
							</div>
							<div className="page__main-typed">
								<Typed
									strings={['Increase Your Fan Base', 'Create Loyalty', 'Create Interactive Polls', 'Boost Your Engagement', 'Engage Your Audience', 'Build Your Brand', 'Create Digital Campaigns ']}
									typeSpeed={100}
									backSpeed={60}
									loop
								/>
							</div>
							<div className="font--23">
								{t('main.subHeader')}
							</div>
							<div className="text--muted font--18 mtop--10">
								{t('main.click')}
							</div>
							<div className="landing__card-container mtop--20">
								{ features.map((feature, idx) => {
									return (
										<div className={`landing__card ${currentFeature?.title === feature.title && 'landing__card-active'}`} key={`card__${idx}`} onClick={() => setCurrentFeature(feature)}>
											<img src={feature.image} alt="Alt image" className="img__responsive" />
											<div className="landing__card-text">{feature.title}</div>
										</div>
									)
								})}
							</div>
							{/* <a className="btn btn-primary btn-rounded btn__main text--normal" href="/#pricing">
								{t('main.start')}
							</a> */}
						</div>
					</div>
					<div className='right'>
						<h2>{currentFeature?.title}</h2>
						<div className={`page__main-features ${isFullHeight ? `page__main-featuresCollapsible` : ''}`}>
							{ currentFeature.iframe ? 
								<iframe frameBorder='0' border='0' cellSpacing='0' style={{ borderStyle: 'none', width: '100%', height: '750px' }} src={currentFeature.iframe}></iframe>
								: 
								<div style={{height: '600px'}} className="font--35 text--bold">Coming soon</div>
							}
						</div>
						<div className="mtop--20 text--center">
							<button className="btn btn-secondary page__main-featuresCollapsibleButton" onClick={() => setIsFullHeight(!isFullHeight)}>
								{ isFullHeight ?
									<>
										<span className="material-icons font--25">
											arrow_upward
										</span>
										Hide full demo
									</>
									:
									<>
										<span className="material-icons font--25">
											arrow_downward
										</span>
										Show full demo
									</>
								}
								
							</button>
						</div>
						{/* <div className="mtop--20 flex flex--row justify--center">
							<h2>{t('main.choose')}</h2>
						</div> */}
					</div>
				</div>
				{/* <div className="mtop--20 landing__input">
					<div className="landing__input-header">
						<label className="font--22 text--bold">Get notified with our latest updates?</label>
					</div>
					<input type="email"  placeholder='Type your email...' style={{width: '50%'}} />
					<div className="btn btn-primary btn-sm">
						<span className="material-icons font--40">
							send
						</span>
					</div>
				</div> */}
				<div id="promo-video">
					<RenderIfVisible defaultHeight={640} visibleOffset={-100} stayRendered={true}>
						<PromoVideo />
					</RenderIfVisible>
				</div>
				<div id="howto">
					<HowTo />
				</div>
				<div id="pricing">
					<Pricing />
				</div>
				<div id="contact">
					<Contact />
				</div>
				<div>
					<Footer />
				</div>
			</div>
		</div>
	);
}

export default Main;
